/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-ipxbgl bg--center --full --parallax" anim={null} name={"uvod-2"} style={{"minHeight":"100vh","backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/11212/5f6fb8a6aa7048c49c71bcb9010dd883_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 mt--40" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--128 w--300 title-box--invert lh--1" content={"Veselé Vánoce"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center ff--3 fs--43 w--300 subtitle-box--invert mt--20" style={{"maxWidth":870}} content={"&amp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert mt--20" style={{"maxWidth":332}} content={"Šťastný nový rok 2021 Vám přeje Milan & Anet"}>
              </Subtitle>

              <Image className="mt--30" alt={""} src={"https://cdn.swbpg.com/o/11212/3fd9035bcee7494e8846a11cc1ede436.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":240}} srcSet={""} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert mt--30" style={{"maxWidth":332}} content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com/cs/pf2021/\">saywebpage.com</a>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}